







































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";

interface dataBanner {
  id: number;
  imagen: string;
  imagen_mobile: string;
  link: string;
}

interface dataComercio {
  foto: string;
  nombre: string;
  nombre_web: string;
}

@Component
export default class Home extends Vue {
  private slide = 0;
  private medium = false;
  private aBanners: Array<dataBanner> = [];
  private aComercios: Array<dataComercio> = [];
  private popUp: any = null;
  private verPopUp = false;
  private horizontalPercentageComercios = 0;
  private slideIndex = 1;
  get footerStyle() {
    let result = "";
    if (this.$q.screen.xs) {
      result = "margin-top: 40px; margin-bottom: 40px;";
    } else {
      result = "margin-top: 30px; margin-bottom: 30px;";
    }
    return result;
  }

  private mounted() {
    this.getBanners();
    this.getComercios();
    this.autoscroll();
    this.getPopUp();
  }

  private getPopUp() {
    this.$axios
      .get("popup")
      .then((res) => {
        this.popUp = res.data;
        if (this.popUp.imagen != "") {
          this.popUp.imagen = "https://d1rofaen6s22p4.cloudfront.net/" + this.popUp.imagen;
        } else {
          this.popUp = null;
          this.verPopUp = false;
          return;
        }
        this.verPopUp = true;
      })
      .catch((err: any) => {
        this.popUp = null;
        this.verPopUp = false;
      });
  }

    private clickPopUp() {
    if (this.popUp && this.popUp.url && this.popUp.url != "") {
      window.open(this.popUp.url, "_blank");
    }
  }

  private updated() {
    this.showSlides(this.slideIndex);
  }

  private getBanners() {
    this.$q.loading.show({
      delay: 100, // ms
    });
    this.$axios("slider")
      .then((res) => {
        this.aBanners = res.data;
        for (let index = 0; index < this.aBanners.length; index++) {
          this.aBanners[index].imagen =
            "https://d1rofaen6s22p4.cloudfront.net/" +
            this.aBanners[index].imagen;
          if (
            this.aBanners[index].imagen_mobile &&
            this.aBanners[index].imagen_mobile != ""
          ) {
            this.aBanners[index].imagen_mobile =
              "https://d1rofaen6s22p4.cloudfront.net/" +
              this.aBanners[index].imagen_mobile;
          } else {
            this.aBanners[index].imagen_mobile = this.aBanners[index].imagen;
          }
          if (!this.aBanners[index].link) {
            this.aBanners[index].link = "#";
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        this.aBanners = [];
      });
  }
  private getComercios() {
    this.$q.loading.show({
      delay: 100, // ms
    });
    this.$axios("comercios/destacados")
      .then((res) => {
        this.aComercios = res.data;
        for (let index = 0; index < this.aComercios.length; index++) {
          this.aComercios[index].foto =
            "https://d1rofaen6s22p4.cloudfront.net/" +
            this.aComercios[index].foto;
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        this.aComercios = [];
      });
  }

  private scroll(direccion) {
    var size = (this.$refs.scrollComercios as any).scrollSize;
    var position = (this.$refs.scrollComercios as any).scrollPosition;

    if (direccion == "derecha") {
      if (this.horizontalPercentageComercios >= 1) {
        (this.$refs.scrollComercios as any).setScrollPosition(0, 200);
      } else {
        (this.$refs.scrollComercios as any).setScrollPosition(
          position + 286,
          200
        );
      }
    } else {
      if (position - 286 < 0) {
        if (position > 0) {
          (this.$refs.scrollComercios as any).setScrollPosition(0, 200);
        } else {
          (this.$refs.scrollComercios as any).setScrollPosition(size, 200);
        }
      } else {
        (this.$refs.scrollComercios as any).setScrollPosition(
          position - 286,
          200
        );
      }
    }
  }

  private scrollAreaFunctionComercios(info) {
    this.horizontalPercentageComercios = info.horizontalPercentage;
  }

  private toLink(link) {
    if (link != "#") {
      window.open(link, "_self");
    }
  }

  private video(url: any) {
    url = url.replace("watch?v=", "embed/");
    return url;
  }

  private irVideo(link) {
    if (link != "#") {
      window.open(link, "_blank");
    }
  }

  private toApp(link) {
    if (link != "#") {
      window.open(link, "_blank");
    }
  }

  private irBeneficio(nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Beneficio" &&
      this.$router.currentRoute.params.name == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Beneficio",
          params: { nombre: nombreWeb },
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  // Funciones del banner
  // Next/previous controls
  private plusSlides(n) {
    this.showSlides((this.slideIndex += n));
  }

  // Thumbnail image controls
  private currentSlide(n) {
    this.showSlides((this.slideIndex = n));
  }

  private showSlides(n) {
    var i;
    var slides = document.getElementsByClassName("mySlides");
    var dots = document.getElementsByClassName("dot");
    if (n > slides.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = slides.length;
    }
    if (slides.length > 0) {
      for (i = 0; i < slides.length; i++) {
        (slides[i] as any).style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }

      (slides[this.slideIndex - 1] as any).style.display = "block";
      dots[this.slideIndex - 1].className += " active";
    }
  }

  private autoscroll() {
    this.plusSlides(1);
    setTimeout(this.autoscroll, 5000);
  }
  // Fin Funciones del banner
}
