






import { Component, Vue } from "vue-property-decorator";
import ValidateAccount from "@/components/auth/ValidateAccount.vue";

@Component({
  components: {
    ValidateAccount
  },
  meta: {
    // sets document title
    title: "Validar - Tarjeta Tu Puente",
    // optional; sets final title as "Index Page - My Website", useful for multiple level meta
    //titleTemplate: title => `${title} - My Website`,

    // meta tags
    meta: {
      description: {
        name: "description",
        content: "Validar Cuenta Tarjeta Tu Puente."
      },
      keywords: { name: "keywords", content: "Tarjeta Tu Puente" },
      equiv: {
        "http-equiv": "Content-Type",
        content: "text/html; charset=UTF-8"
      }
    }
  }
})
export default class ValidateAccountView extends Vue {}
