












































































































































































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";

@Component({
  components: {}
})
export default class HazteSocio extends Vue {
  private ver = false;
  private routerGo(value: string) {
    if (value && value != "") {
      if (this.$router.currentRoute.name == value) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: value }).catch(err => {
          console.log(err);
        });
      }
    }
  }
}
