






import { Vue, Component, Prop } from "vue-property-decorator";
import HazteSocio from "@/components/Socios/HazteSocio.vue";

@Component({
  components: {
    HazteSocio
  }
})
export default class HazteSocioView extends Vue {
  private mounted() {}
}
