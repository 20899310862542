















































































































































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import TerminosYCondicionesComponent from "@/components/Common/TerminosYCondicionesComponent.vue";
import FormValidator from "@/components/mixins/FormValidator.ts";

interface SignupData {
  name: string;
  family_name: string;
  rut: string;
  email: string;
  phone: number | null;
  password: string;
  password_confirmation: string;
}

@Component({
  components: {
    TerminosYCondicionesComponent
  }
})
export default class RegisterComponent extends Mixins(FormValidator) {
  private formattedRut: string = "";
  private loading = false;
  private dialogTerms = false;
  private terms: boolean = false;
  private customModel = "no";
  private bTerminos = false;
  private terminosFooter = false;
  protected formData: SignupData = {
    name: "",
    family_name: "",
    rut: "",
    email: "",
    phone: null,
    password: "",
    password_confirmation: ""
  };

  @Watch("formattedRut")
  private formatRut(newValue: string) {
    newValue = newValue.replace(/\./g, "").replace(/-/g, "");
    this.formData.rut = newValue;
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1).toUpperCase();
      this.formattedRut = `${rut}-${dv}`;
    } else {
      this.formattedRut = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }

  private registrar() {
    if (
      !this.formData.rut ||
      !this.validateRut(this.formData.rut) ||
      this.formData.rut.trim() == ""
    ) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("notifications.error.NO_RUT").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    } else if (!this.formData.name || this.formData.name.trim() == "") {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("notifications.error.NO_NAME").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    } else if (
      !this.formData.family_name ||
      this.formData.family_name.trim() == ""
    ) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("notifications.error.NO_APELLIDOS").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    } else if (!this.formData.email || this.formData.email.trim() == "") {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("notifications.error.NO_EMAIL").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    } else if (!this.validEmail(this.formData.email)) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("notifications.error.FORMAT_EMAIL").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    } else if (!this.formData.phone || this.formData.phone == 0) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("notifications.error.NO_PHONE").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    } else if (this.formData.phone && this.formData.phone <= 99999999) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("notifications.error.FORMAT_PHONE").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    } else if (
      !this.formData.password ||
      this.formData.password.trim() == "" ||
      this.formData.password.length < 6
    ) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("messages.password_length_message").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    } else if (this.formData.password != this.formData.password_confirmation) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("messages.not_match_password").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    } else if (this.customModel == "no" || !this.customModel) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("notifications.error.ERROR_TERMINOS").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.loading = true;
    const rut = this.formData.rut.substring(0, this.formData.rut.length - 1);
    const dv = this.formData.rut.substring(this.formData.rut.length - 1);
    const formattedRut = `${rut}-${dv}`;
    this.$axios
      .post("user/registro", {
        username: this.formData.name + " " + this.formData.family_name,
        rut: formattedRut,
        email: this.formData.email,
        telefono: this.formData.phone,
        password: this.formData.password
      })
      .then(res => {
        this.loading = false;
        this.$swal({
          icon: "success",
          title: this.$t("notifications.titles.register").toString(),
          text: this.$t("notifications.success.signup", {
            email: this.formData.email
          }).toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        });
        this.$q.loading.hide();
        if (this.$router.currentRoute.name == "login") {
          this.$router.go(0);
        } else {
          this.$router.push({ name: "login" }).catch(err => {});
        }
      })
      .catch(error => {
        const message = error.response.data.message;
        const type = error.response.data.type;
        let text: string = "";
        if (message === "EMAIL_USED") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("notifications.error.REGISTERED_EMAIL", {
              email: this.formData.email
            }).toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "RUT_USED") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("notifications.error.RUT_USED", {
              rut: formattedRut
            }).toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "RUT_INVALID") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("notifications.error.RUT_INVALID", {
              rut: formattedRut
            }).toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "REGISTERED_PHONE") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("notifications.error.REGISTERED_PHONE").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "REGISTERED_RUT") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("notifications.error.RUT_USED", {
              rut: formattedRut
            }).toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "REGISTERED_EMAIL") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("notifications.error.REGISTERED_EMAIL").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "INVALID_EMAIL") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("notifications.error.INVALID_PARAM_EMAIL").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "USED_PHONE") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("notifications.error.REGISTERED_PHONE").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "USED_EMAIL") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("notifications.error.REGISTERED_EMAIL").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "INVALID_RUT") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("notifications.error.RUT_USED").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "INVALID_PARAM_TELEFONO") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("notifications.error.INVALID_PARAM_PHONE").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("notifications.error.ERROR_FORM").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        }
        this.$q.loading.hide();
        this.loading = false;
      });
  }

  private onCloseTerminos(info) {
    if (info.footer == true) {
      if (info.response == true) {
        this.customModel = "si";
        this.bTerminos = false;
      } else if (info.response == false) {
        this.customModel = "no";
        this.bTerminos = false;
      } else {
        this.bTerminos = false;
      }
    } else {
      this.bTerminos = false;
    }
  }

  private mostrarTerminos(footer) {
    this.terminosFooter = footer;
    this.bTerminos = true;
  }

  private validEmail(email) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(email) === true) {
      return true;
    } else {
      return false;
    }
  }

  public validateRut(newValue: string) {
    var texto = newValue;
    var tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    var largo = texto.length;
    if (largo < 2) {
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        return false;
      }
    }
    var invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    var dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    var cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      //alert("i=[" + i + "] j=[" + j +"]" );
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      return true;
    }
    return false;
  }
  public revisarDigito(dvr: string) {
    var dv = dvr + "";
    if (
      dv != "0" &&
      dv != "1" &&
      dv != "2" &&
      dv != "3" &&
      dv != "4" &&
      dv != "5" &&
      dv != "6" &&
      dv != "7" &&
      dv != "8" &&
      dv != "9" &&
      dv != "k" &&
      dv != "K"
    ) {
      return false;
    }
    return true;
  }
  public revisarDigito2(crut: string) {
    var largo = crut.length;
    if (largo < 2) {
      return false;
    }
    if (largo > 2) var rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    var dv = crut.charAt(largo - 1);
    this.revisarDigito(dv);
    if (rut == null || dv == null) return 0;
    var dvr = "0";
    var suma = 0;
    var mul = 2;
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + parseInt(rut.charAt(i), 10) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    var res = suma % 11;
    if (res == 1) dvr = "k";
    else if (res == 0) dvr = "0";
    else {
      var dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }
    return true;
  }
}
