






import { Vue, Component, Prop } from "vue-property-decorator";
import Home from "@/components/Home.vue";

@Component({
  components: {
    Home
  },
  meta: {
    // sets document title
    title: "Home Tarjeta Tu Puente",
    // optional; sets final title as "Index Page - My Website", useful for multiple level meta
    //titleTemplate: title => `${title} - My Website`,

    // meta tags
    meta: {
      description: {
        name: "description",
        content: "Tarjeta Tu Puente"
      },
      keywords: { name: "keywords", content: "PuenteAlto" },
      equiv: {
        "http-equiv": "Content-Type",
        content: "text/html; charset=UTF-8"
      }
    }
  }
})
export default class HomeView extends Vue {
  private mounted() {
    document.title = "Home - Tarjeta Tu Puente";
  }
}
